import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/Root';
import JoinTheFun from './routes/JoinTheFun';
import { useFonts } from 'expo-font';
import GameCenter from './routes/GameCenter';
import GameCenterChallenge from './routes/GameCenterChallenge';
import { QueryClient, QueryClientProvider, focusManager } from '@tanstack/react-query';
import { AppStateStatus, Platform } from 'react-native';
import { useAppState } from './hooks/useAppState';
import Constants from 'expo-constants';
import { createContext, useReducer, useState } from 'react';
import { Challenge } from './interfaces';
import GameCenterLite from './routes/lite/GameCenterLite';
import { ErrorPage } from './components/ErrorPage';

// Access your API URL
export const apiUrl = Constants?.manifest?.extra?.apiUrl;
export const isNotProd = JSON.parse(Constants?.manifest?.extra?.isNotProd);

export type WindowContext = {
  accountId: string;
  authToken: string;
  pxHeaders: string;
  challenge: Challenge | undefined;
  setChallenge: (challenge: Challenge | undefined) => void;
  isPhone: boolean;
  hideDevBanner: boolean;
  toggleHideDevBanner: () => void;
};

export const WindowContext = createContext({
  accountId: '',
  authToken: '',
  pxHeaders: '',
  challenge: undefined,
  setChallenge: () => {},
  isPhone: false,
  hideDevBanner: false,
  toggleHideDevBanner: () => {},
} as WindowContext);

const WindowProvider = ({ children }: { children: React.ReactNode }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const account = urlParams.get('account');
  const accountFromSession = (window as any).localStorage.getItem('account');
  const [challenge, setChallenge] = useState<Challenge | undefined>();
  const [hideDevBanner, toggleHideDevBanner] = useReducer((state) => !state, false);

  if (account && account !== accountFromSession) {
    window.localStorage.setItem('account', account);
  }

  const accountId = window.__AccountID || (isNotProd && accountFromSession) || account;
  const phone = !!new URLSearchParams(window.location.search).get('phone');

  return (
    <WindowContext.Provider
      value={{
        accountId,
        authToken: window.__AuthToken as string,
        pxHeaders: window.__pxHeaders as string,
        challenge,
        setChallenge,
        isPhone: phone,
        hideDevBanner,
        toggleHideDevBanner,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/CountryNotAllowed',
    element: <ErrorPage error="Game Center not available outside US." />,
  },
  {
    path: 'GameCenter',
    element: <GameCenter />,
  },
  {
    path: 'GameCenter/:challengeId',
    element: <GameCenterChallenge />,
  },
  {
    path: 'GameCenter/JoinTheFun/:inviteCode',
    element: <JoinTheFun />,
  },
  {
    path: 'GameCenter/lite',
    element: <GameCenterLite />,
  },
]);

function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== 'web') {
    focusManager.setFocused(status === 'active');
  }
}

export const queryClient = new QueryClient();

export default function App() {
  /* const [fontsLoaded] = */ useFonts({
    'GT-Walsheim-Regular': 'https://a.vsstatic.com/font/GT-Walsheim-Regular.woff2',
    'GT-Walsheim-Medium': 'https://a.vsstatic.com/font/GT-Walsheim-Medium.woff2',
    'GT-Walsheim-Bold': 'https://a.vsstatic.com/font/GT-Walsheim-Bold.woff2',
    'GT-Walsheim-Black': 'https://a.vsstatic.com/font/GT-Walsheim-Black.woff2',
  });

  useAppState(onAppStateChange);

  return (
    <WindowProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </WindowProvider>
  );
}
