import { View } from 'react-native';
import { Challenge } from '../interfaces';
import DText from './DText';
import { colorPalette } from '../themes/variables';
import { YourSelectionCard } from './YourSelectionCard';
import { useContext } from 'react';
import { WindowContext } from '../App';

export function YourSelections({challenge}: {challenge: Challenge}) {
    const games = challenge.games?.map((game) => game).flat();

    const { accountId } = useContext(WindowContext);

    if (!games?.some((game) => game.plays.length > 0)) {
        return null;
    }
    return (
        <View style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            marginVertical: 16,
        }}>
            <DText color={colorPalette.neutral[200]} fontSize={18} fontWeight={800}>
                Your Selections
            </DText>
            {games?.map((game) => {
                return <YourSelectionCard key={game.id} game={game} userId={accountId} />
            })}
        </View>
    )
}