import { GameCenterContainer } from '../components/GameCenterContainer';
import { ChallengeCard } from '../components/ChallengeCard';
import { LoadingPage } from '../components/LoadingPage';
import { ErrorPage } from '../components/ErrorPage';
import { useQuery } from '@tanstack/react-query';
import { getChallengeV2 } from '../api';
import { LeagueSection } from '../components/LeagueSection';
import { Challenge } from '../interfaces';
import { YourSelections } from '../components/YourSelections';
import { DEVBanner } from '../components/DEVBanner';
import { useContext } from 'react';
import { WindowContext } from '../App';

export default function GameCenterChallenge() {
  const windowValues = useContext(WindowContext);
  const challengeId = windowValues.challenge?.id;

  const {
    isLoading: challengeLoading,
    isError: isChallengeError,
    error,
    data: challenge,
  } = useQuery<Challenge, Error, Challenge, (string | number | undefined)[]>({
    queryKey: ['ChallengeData', challengeId],
    queryFn: getChallengeV2(windowValues),
    enabled: Boolean(windowValues.accountId) && Boolean(challengeId),
  });

  scrollTo({ top: 0, behavior: 'instant' });

  if (challengeLoading) {
    return <LoadingPage />;
  }

  if (isChallengeError) {
    return <ErrorPage error={error?.message || 'Unknown error'} />;
  }

  return (
    <GameCenterContainer shortHeader showRulesButton>
      <DEVBanner shortHeader />
      <ChallengeCard challenge={challenge} withBackButton onChallengePage={true} />
      <YourSelections challenge={challenge} />
      <LeagueSection />
    </GameCenterContainer>
  );
}
