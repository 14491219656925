import { useContext, useEffect, useMemo, useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';

import { GameCenterTab } from '../components/GameCenterTab';
import { GameCenterContainer } from '../components/GameCenterContainer';
import { GameCenterGames } from '../components/GameCenterGames';
import { GameCenterHistory } from '../components/GameCenterHistory';
import { GameCenterLeagues } from '../components/GameCenterLeagues';
import { DEVBanner } from '../components/DEVBanner';
import { WindowContext } from '../App';
import { ErrorPage } from '../components/ErrorPage';
import GameCenterChallenge from './GameCenterChallenge';
import BiggerTouchableOpacity from '../components/BiggerTouchableOpacity';
import { colorPalette } from '../themes/variables';
import DText from '../components/DText';
import Fonts from '../constants/Fonts';
import { getChallengeV2, getLeagueV2, updateLeagueV2 } from '../api';
import { useQuery } from '@tanstack/react-query';
import { Challenge, League } from '../interfaces';
import { LoadingPage } from '../components/LoadingPage';
import { LeagueLeaderboard } from '../components/LeagueLeaderboard';

export type GameCenterTabs = 'Games' | 'History' | 'Leagues';
export default function GameCenter() {
  const [activeTab, setActiveTab] = useState<GameCenterTabs>('Games');

  const windowValues = useContext(WindowContext);
  const validAuthNeeded = process.env.NODE_ENV !== 'development';
  const invalidAuth = !parseInt(windowValues.accountId) || !windowValues.authToken;

  const urlParams = new URLSearchParams(window.location.search);
  const paramChallengeId = urlParams.get('challengeId') || undefined;

  const [challengeId, setChallengeId] = useState(windowValues.challenge?.id ?? paramChallengeId);

  const referrerId = urlParams.get('referralId');
  const leagueId = urlParams.get('leagueId') || undefined;

  const promoCode = urlParams.get('promoCode');

  const { accountId } = windowValues;
  if (referrerId && leagueId && accountId) {
    const definition = { referrees: { [accountId]: { referrerId, timestamp: new Date() } } };
    useMemo(async () => {
      const league = await updateLeagueV2(windowValues)({
        queryKey: ['leagueId', leagueId],
        league: { definition },
      });
      const challengeId = league?.definition.referrers[referrerId]?.challengeId;
      window.location.replace(window.location.href.split(`?`)[0] + `?challengeId=${challengeId}`);
      setChallengeId(challengeId);
    }, [accountId, referrerId, leagueId]);
  }

  useEffect(() => {
    if (promoCode !== null) {
      setActiveTab('History');
    }
  }, [promoCode]);

  const { data: challenge, isInitialLoading: challengeLoading } = useQuery<
    Challenge,
    Error,
    Challenge,
    (string | number | undefined)[]
  >({
    queryKey: ['ChallengeData', challengeId],
    queryFn: challengeId ? getChallengeV2(windowValues) : undefined,
    enabled: Boolean(windowValues.accountId) && Boolean(challengeId),
  });

  const {
    data: league,
    isInitialLoading: leagueLoading,
    refetch: refetchLeagues,
  } = useQuery<League, Error, League, (string | undefined)[]>({
    queryKey: ['leagueData', leagueId],
    queryFn: leagueId ? getLeagueV2(windowValues) : undefined,
    enabled: Boolean(windowValues.accountId) && Boolean(leagueId),
  });

  useEffect(() => {
    if (challenge) {
      windowValues.setChallenge(challenge);
    }
    return;
  }, [challenge, windowValues]);

  const [width, setWidth] = useState(320);

  if (challengeLoading || leagueLoading) {
    return <LoadingPage />;
  }

  if (validAuthNeeded && invalidAuth) {
    return ErrorPage({
      error: `Could not load GameCenter. Please make sure you are logged in.`,
      extra: (
        <View
          style={{
            paddingHorizontal: 16,
          }}
        >
          <BiggerTouchableOpacity
            onPress={() => {
              location.href = 'vividseats://login';
            }}
            style={{
              width: '100%',
              height: 36,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
              backgroundColor: colorPalette.indigo['400'],
              paddingTop: 6,
              paddingBottom: 6,
              paddingRight: 12,
              paddingLeft: 12,
            }}
          >
            <DText color={colorPalette.white['100']} fontSize={Fonts.font16}>
              Log in
            </DText>
          </BiggerTouchableOpacity>
        </View>
      ),
    });
  }

  if (windowValues.challenge) {
    return <GameCenterChallenge />;
  }
  if (league) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colorPalette.neutral[800],
          flexDirection: 'column',
          maxWidth: 475,
        }}
        onLayout={(event: LayoutChangeEvent) => {
          const { layout } = event.nativeEvent;
          setWidth(layout.width);
        }}
      >
        <LeagueLeaderboard
          setSelectedLeague={() => window.location.replace(window.location.href.split('?')[0])}
          leagueId={league?.id}
          leagueType={league?.type}
          width={width - 24}
          refetchLeagues={refetchLeagues}
        />
      </View>
    );
  }

  return (
    <GameCenterContainer>
      <DEVBanner />
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 12,
          display: 'flex',
        }}
      >
        <GameCenterTab setActiveTab={setActiveTab} activeTab={activeTab}>
          Games
        </GameCenterTab>
        <GameCenterTab setActiveTab={setActiveTab} activeTab={activeTab}>
          History
        </GameCenterTab>
        <GameCenterTab setActiveTab={setActiveTab} activeTab={activeTab}>
          Leagues
        </GameCenterTab>
      </View>

      {activeTab === 'Games' && <GameCenterGames />}
      {activeTab === 'History' && <GameCenterHistory />}
      {activeTab === 'Leagues' && <GameCenterLeagues />}
    </GameCenterContainer>
  );
}
