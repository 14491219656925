import { useContext, useState } from 'react';
import {
  ActivityIndicator,
  LayoutChangeEvent,
  Modal,
  StyleSheet,
  TextInput,
  View,
  ScrollView,
} from 'react-native';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import Margin from './Margin';
import CtaButton from './CtaButton';
import Colors from '../constants/Colors';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { Check, ChevronDown, ChevronUp, Close } from './images';
import { LeagueCreate } from './LeagueCreate';
import { LeagueLeaderboard } from './LeagueLeaderboard';
import { LeagueList } from './LeagueList';
import { LoadingPage } from './LoadingPage';
import { ErrorPage } from './ErrorPage';
import { getLeaguesV2, joinLeagueV2 } from '../api';
import { Challenge, League, Play } from '../interfaces';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { TextBox } from './TextBox';
import { YourSelectionCard } from './YourSelectionCard';
import { PromotionCard } from './PromotionCard';
import { WindowContext } from '../App';
import { getChallengeIcon } from '../Utils';

export function LeagueSection() {
  const windowValues = useContext(WindowContext);
  const { challenge } = windowValues;
  const { challengeId } = useParams();

  const {
    isLoading: leaguesLoading,
    isError: isLeaguesError,
    data: leagues,
    error: leaguesError,
    refetch: refetchLeagues,
  } = useQuery<League[], Error>({
    queryKey: ['GameCenterData-Leagues'],
    queryFn: getLeaguesV2(windowValues),
  });

  const [showLeagueCreate, setShowLeagueCreate] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState<League>();
  const [width, setWidth] = useState(320);

  const { accountId } = useContext(WindowContext);

  if (leaguesLoading) {
    return <LoadingPage />;
  }

  if (isLeaguesError || (challengeId && !challenge)) {
    return <ErrorPage error={leaguesError?.message || 'Unknown error'} />;
  }
  const leaguesForList = challenge
    ? leagues.filter((league) => league.type === challenge.type)
    : leagues;

  return (
    <View
      style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      onLayout={(event: LayoutChangeEvent) => {
        const { layout } = event.nativeEvent;
        setWidth(layout.width);
      }}
    >
      <DText
        color={colorPalette.neutral[200]}
        fontSize={18}
        fontWeight={800}
        style={{
          marginTop: challenge?.games?.find((game) =>
            game.plays.find((play) => play.userId === accountId),
          )
            ? 0
            : 12,
        }}
      >
        Leagues
      </DText>
      {leaguesForList.length > 0 && (
        <LeagueList
          leagues={leaguesForList}
          width={width}
          setSelectedLeague={setSelectedLeague}
          challengeType={challenge?.type}
        />
      )}
      <View
        style={{
          width: '100%',
          minHeight: 64,
          padding: 8,
          borderRadius: 8,
          backgroundColor: 'rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DText color="white" fontSize={Fonts.font20} lineHeight={'125%'} fontWeight={900}>
          Want to Play With Friends?
        </DText>
        <Margin height={4} />
        <DText color={colorPalette.neutral['200']} fontSize={Fonts.font14} lineHeight={'150%'}>
          Create a league and compete against friends.
        </DText>
        <Margin height={16} />
        <CtaButton
          width={'100%'}
          onPress={() => setShowLeagueCreate(true)}
          active={true}
          text="Create a League"
        />
        <Margin height={8} />
      </View>
      <View>
        <Modal
          style={{
            width: '100%',
            minHeight: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,.2)',
          }}
          animationType={'fade'}
          transparent={true}
          onRequestClose={() => false}
          visible={showLeagueCreate}
        >
          {showLeagueCreate && (
            <View
              key="renderLeagueCreate"
              style={[styles.mainContainer, { backgroundColor: 'rgba(0,0,0,.8)' }]}
            >
              <View
                style={[
                  styles.container,
                  { backgroundColor: Colors.betchaBlack40Percent },
                  {
                    marginTop: 12,
                    flex: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                <LeagueCreate
                  width={width}
                  onCancel={() => setShowLeagueCreate(false)}
                  setSelectedLeague={setSelectedLeague}
                />
              </View>
            </View>
          )}
        </Modal>
        <LeagueInviteWidget
          width={width}
          setSelectedLeague={setSelectedLeague}
          refetchLeagues={refetchLeagues}
        />
        {selectedLeague && (
          <Modal
            style={{
              width: '100%',
              minHeight: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: 'rgba(255,255,255,.2)',
            }}
            animationType={'fade'}
            transparent={true}
            onRequestClose={() => false}
            visible={!!selectedLeague}
          >
            <View
              key="leagueLeaderboard"
              style={[styles.mainContainer, { backgroundColor: 'rgba(0,0,0,.8)' }]}
            >
              <View
                style={[
                  styles.container,
                  { backgroundColor: Colors.betchaBlack40Percent },
                  {
                    flex: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                {selectedLeague && (
                  <LeagueLeaderboard
                    refetchLeagues={refetchLeagues}
                    width={width}
                    leagueId={selectedLeague.id}
                    leagueType={selectedLeague.type}
                    setSelectedLeague={setSelectedLeague}
                  />
                )}
              </View>
            </View>
          </Modal>
        )}
        <PromotionCard />
      </View>
    </View>
  );
}

function LeagueInviteWidget(props: {
  width: number;
  setSelectedLeague: (league: League) => void;
  refetchLeagues: () => Promise<any>;
}) {
  const [inviteCode, setInviteCode] = useState('');
  const [showLeagueJoin, setShowLeagueJoin] = useState(false);
  const [leagueToJoin, setLeagueToJoin] = useState<League>();
  const [showError, setShowError] = useState('');
  const windowValues = useContext(WindowContext);
  const submitInviteCode = async (inviteCode: string) => {
    try {
      const league = await joinLeagueV2(windowValues)({ inviteCode });
      setLeagueToJoin(league);
      setShowLeagueJoin(true);
      setInviteCode('');
    } catch (err) {
      setShowError((err as { message: string }).message);
    }
  };

  const textStyle1: any = {
    pattern: '[0-9]*',
    inputMode: 'decimal',
    padding: 12,
    type: 'number',
    color: 'white',
    fontFamily: 'GT-Walsheim-Regular',
    fontSize: 14,
    height: 48,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: 'rgba(255,255,255,.1)',
    width: '100%',
  };

  if (showError) {
    return <ErrorPage error={showError || 'Unknown error'} />;
  }

  return (
    <View
      key={'enterleagueBoard'}
      style={{
        width: '100%',
        minHeight: 64,
        padding: 8,
        borderRadius: 8,
        backgroundColor: 'rgba(255,255,255,.1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DText color="white" fontSize={Fonts.font20} lineHeight={'125%'} fontWeight={900}>
        Have An Invite Code?
      </DText>
      <Margin height={4} />
      <DText color={colorPalette.neutral['200']} fontSize={Fonts.font14} lineHeight={'150%'}>
        {'Add your invite code to join a league.'}
      </DText>
      <Margin height={16} />
      <TextInput
        spellCheck={false}
        placeholder={'Invite Code'}
        autoCorrect={false}
        autoComplete={'off'}
        maxLength={7}
        onChangeText={setInviteCode}
        placeholderTextColor={colorPalette.neutral['200']}
        style={textStyle1}
        value={inviteCode}
      />
      <Margin height={16} />
      <CtaButton
        width={'100%'}
        onPress={() => submitInviteCode(inviteCode)}
        active={inviteCode.length === 7}
        text="Join"
      />
      <Margin height={8} />
      <Modal
        style={{
          width: '100%',
          minHeight: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: 'rgba(255,255,255,.2)',
        }}
        animationType={'fade'}
        transparent={true}
        onRequestClose={() => false}
        visible={showLeagueJoin}
      >
        {showLeagueJoin && (
          <View key="renderLeagueJoin" style={styles.mainContainer}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  marginTop: 12,
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <LeagueJoin
                leagueToJoin={leagueToJoin}
                width={props.width}
                setShowLeagueJoin={setShowLeagueJoin}
                setSelectedLeague={props.setSelectedLeague}
                refetchLeagues={props.refetchLeagues}
              />
            </View>
          </View>
        )}
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,.8)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
    maxWidth: 475,
  },
});

export function LeagueJoin(props: {
  width: number;
  leagueToJoin?: League;
  setShowLeagueJoin: (show: boolean) => void;
  setSelectedLeague: (league: League) => void;
  refetchLeagues: () => Promise<any>;
}) {
  let memberNumber = 1;
  const getDefaultMemberName = () => 'user-' + memberNumber;
  while (props.leagueToJoin?.members.some((member) => member.name === getDefaultMemberName())) {
    memberNumber++;
  }
  const [userName, setUserName] = useState(getDefaultMemberName());
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState('');

  const windowValues = useContext(WindowContext);
  if (!props.leagueToJoin || showError) {
    return <ErrorPage error={showError || 'Unknown error'} />;
  }

  const joinLeague = async (inviteCodeAndName: Partial<League>) => {
    setSubmitting(true);
    try {
      const league = await joinLeagueV2(windowValues)(inviteCodeAndName);
      props.setSelectedLeague(league);
      props.setShowLeagueJoin(false);
      await props.refetchLeagues();
    } catch (err) {
      setShowError((err as { message: string }).message);
    }
    setSubmitting(false);
  };

  return (
    <View
      key={'enterleagueBoard'}
      style={{
        width: '100%',
        minHeight: 64,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Margin height={16} />
      <View
        style={{
          width: 48,
          height: 48,
          borderRadius: 24,
          backgroundColor: 'rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DText
          style={{ backgroundColor: 'transparent' }}
          fontSize={32}
          color="white"
          fontWeight={900}
        >
          {getChallengeIcon(props.leagueToJoin.type)}
        </DText>
      </View>
      <Margin height={8} />
      <DText color="white" fontSize={Fonts.font16} lineHeight={'150%'} fontWeight={900}>
        Welcome to
      </DText>
      <Margin height={8} />
      <DText
        center={true}
        color="white"
        fontSize={Fonts.font20}
        lineHeight={'150%'}
        fontWeight={900}
      >
        {props.leagueToJoin.name}
      </DText>
      <Margin height={8} />
      <DText center={true} color={'white'} fontSize={Fonts.font14} lineHeight={'150%'}>
        {'You’re almost there. Pick a display name or\ncontinue to view the leaderboard.'}
      </DText>
      <Margin height={10} />
      <TextBox
        label={'Display Name'}
        value={userName}
        onChangeText={setUserName}
        width={props.width - 32}
      />
      <Margin height={32} />
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <CtaButton
          outline={true}
          active={true}
          text="Cancel"
          onPress={() => props.setShowLeagueJoin(false)}
        />
        <CtaButton
          onPress={() => joinLeague({ inviteCode: props.leagueToJoin?.inviteCode, name: userName })}
          active={props.leagueToJoin.name.trim().length > 0 && userName.trim().length > 0}
          text="Continue"
        />
      </View>
      <Margin height={16} />
      <BiggerTouchableOpacity
        disabled={submitting}
        onPress={() => props.setShowLeagueJoin(false)}
        style={{
          position: 'absolute',
          top: 12,
          right: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Close height={18} width={18} tintColor={'white'} />
      </BiggerTouchableOpacity>
      {submitting && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: Colors.betchaBlack80Percent,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator animating={true} size={'large'} color={Colors.white} />
        </View>
      )}
    </View>
  );
}

const monthText = (month: number, short = false) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthsShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (month < 0) {
    month = 0;
  } else if (month > months.length - 1) {
    month = months.length;
  }
  return short ? monthsShort[month] : months[month];
};

export const LeaguePlayerProfile = ({
  width,
  selectedMember,
  activeMember,
  league,
  challenges,
  closeProfile,
}: {
  width: number;
  league: League;
  challenges: Challenge[];
  selectedMember: League['members'][number];
  activeMember: League['members'][number];
  closeProfile: () => void;
}) => {
  const windowValues = useContext(WindowContext);
  const activeMemberPlays = league.plays.filter((play) => play.userId === activeMember.userId);
  const selectedMemberPlays = league.plays.filter((play) => play.userId === selectedMember.userId);
  const selectedMemberChallenges = challenges.filter(
    (challenge) =>
      challenge.active ||
      challenge.games?.some((game) => selectedMemberPlays.some((play) => play.gameId === game.id)),
  );
  const [visibleChallenge, setVisibleChallenge] = useState<Challenge>(selectedMemberChallenges[0]);
  const [selectDateOpen, setSelectDateOpen] = useState(false);
  const [popupDateLocation, setPopupDateLocation] = useState(50);

  const playedVisibleGame = (play: Play) =>
    visibleChallenge?.games?.some((game) => game.id === play.gameId);
  const activeMemberPlayed = activeMemberPlays.some(playedVisibleGame);
  const selectedMemberPlayed = selectedMemberPlays.some(playedVisibleGame);

  const renderDateChoices = () => {
    if (!selectDateOpen) {
      return;
    }

    if (!selectedMember) {
      return <View />;
    }

    return (
      <View
        style={{
          width: width - 32,
          position: 'absolute',
          top: popupDateLocation + 4,
        }}
      >
        <View
          style={{
            marginLeft: 16,
            justifyContent: 'flex-start',
            backgroundColor: colorPalette.neutral['800'],
            width: 150,
            borderRadius: 4,
            paddingTop: 8,
          }}
        >
          {selectDateOpen &&
            selectedMemberChallenges.map((challenge, index: number) => {
              const startDate = new Date(challenge.startDate);
              const endDate = new Date(challenge.endDate);
              const drawBorder = index !== selectedMemberPlays.length - 1;
              return (
                <BiggerTouchableOpacity
                  key={'dt' + index}
                  style={{ marginTop: index > 0 ? 8 : 0 }}
                  onPress={() => {
                    setSelectDateOpen(false);
                    setVisibleChallenge(challenge);
                  }}
                >
                  <View
                    style={{
                      paddingLeft: 4,
                      paddingRight: 4,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DText lineHeight="125%" fontSize={Fonts.font14} color="white">{`${monthText(
                      startDate.getMonth(),
                      true,
                    )}. ${startDate.getDate()} - ${monthText(
                      endDate.getMonth(),
                      true,
                    )}. ${endDate.getDate()} `}</DText>
                    <Check
                      style={{ opacity: challenge.id === visibleChallenge?.id ? 1 : 0 }}
                      width={16}
                      height={16}
                      tintColor="white"
                    />
                  </View>
                  {drawBorder && (
                    <View
                      style={{
                        marginTop: 8,
                        height: 1,
                        width: 150,
                        backgroundColor: colorPalette.neutral['600'],
                      }}
                    />
                  )}
                  {!drawBorder && <Margin height={8} />}
                </BiggerTouchableOpacity>
              );
            })}
        </View>
      </View>
    );
  };

  const renderDateList = () => {
    if (!selectedMember) {
      return <View />;
    }

    const startDate = new Date(visibleChallenge?.startDate);
    const endDate = new Date(visibleChallenge?.endDate);

    return (
      <View
        style={{
          width: width - 32,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginTop: 16,
        }}
        onLayout={(event: LayoutChangeEvent) => {
          const { layout } = event.nativeEvent;
          if (layout.y + layout.height !== popupDateLocation) {
            setPopupDateLocation(layout.y + layout.height);
          }
        }}
      >
        <View
          style={{
            justifyContent: 'flex-start',
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 20,
            paddingTop: 4,
            paddingBottom: 4,
            paddingRight: 8,
            paddingLeft: 8,
          }}
        >
          <BiggerTouchableOpacity
            onPress={() => {
              setSelectDateOpen(!selectDateOpen);
            }}
            style={{
              flexDirection: 'row',
            }}
          >
            <DText lineHeight="125%" fontSize={Fonts.font14} color="white">{`${monthText(
              startDate.getMonth(),
              true,
            )}. ${startDate.getDate()} - ${monthText(
              endDate.getMonth(),
              true,
            )}. ${endDate.getDate()} `}</DText>
            <Margin width={8}></Margin>
            {selectDateOpen && <ChevronUp width={16} height={16} tintColor={'white'} />}
            {!selectDateOpen && <ChevronDown width={16} height={16} tintColor={'white'} />}
          </BiggerTouchableOpacity>
        </View>
      </View>
    );
  };

  const renderUser = () => {
    let totalCorrectString = undefined;

    const entries = selectedMemberPlays
      .filter((play) => visibleChallenge?.games?.find(({ id }) => id === play.gameId))
      .flatMap((play) => play.entries);

    if (selectedMemberPlays.length > 0) {
      let totalCorrect = 0;
      entries?.forEach((entry) => {
        if (entry?.correct) {
          totalCorrect += 1;
        }
        return undefined;
      });
      totalCorrectString = `${totalCorrect}/${entries?.length} Correct`;
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: width - 32,
          paddingRight: 16,
        }}
      >
        <View
          style={{
            backgroundColor: 'rgba(255,255,255,.1)',
            width: 52,
            height: 52,
            borderRadius: 26,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DText
            lineHeight={'150%'}
            style={{ minWidth: 48, paddingRight: 16, paddingLeft: 16 }}
            center={true}
            fontWeight={900}
            color={'white'}
            fontSize={Fonts.font24}
          >
            {selectedMember.ranking.rank || '-'}
          </DText>
        </View>
        <Margin width={16} />
        <View style={{ maxWidth: width * 0.7 }}>
          <DText
            lineHeight={'150%'}
            center={false}
            fontWeight={900}
            color={'white'}
            fontSize={Fonts.font18}
          >
            {'@' + selectedMember.name}
          </DText>
          {selectedMemberPlayed && (
            <DText
              lineHeight={'125%'}
              center={false}
              color={colorPalette.neutral['200']}
              fontSize={Fonts.font14}
            >
              {totalCorrectString}
            </DText>
          )}
        </View>
      </View>
    );
  };

  const renderNotFinished = () => {
    if (!selectedMember) {
      return <View />;
    }
    const title = visibleChallenge?.name;
    return selectedMemberPlayed ? (
      <View
        key={'empty'}
        style={{
          width: '100%',
          borderRadius: 4,
          padding: 16,
          minHeight: 64,
          backgroundColor: 'rgba(255,255,255,.1)', //'#1D2141'
        }}
      >
        <DText fontWeight={900} fontSize={Fonts.font14} color={'white'}>
          {visibleChallenge?.name}
        </DText>
        <Margin height={8} />
        <DText fontSize={Fonts.font14} color={colorPalette.neutral['200']}>
          {'Make your picks to view @' + selectedMember.name + ' selections.'}
        </DText>
        <Margin height={16} />
        <BiggerTouchableOpacity
          onPress={() => {
            windowValues.setChallenge(visibleChallenge);
          }}
          style={{
            width: '100%',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            height: 48,
            backgroundColor: colorPalette.cobalt['400'],
          }}
        >
          <DText fontSize={Fonts.font16} fontWeight={600} color={'white'}>
            Play Now
          </DText>
        </BiggerTouchableOpacity>
      </View>
    ) : (
      <View
        key={'empty'}
        style={{
          width: '100%',
          borderRadius: 4,
          padding: 16,
          minHeight: 64,
          backgroundColor: 'rgba(255,255,255,.1)', //'#1D2141'
        }}
      >
        <DText fontWeight={900} fontSize={Fonts.font14} color={'white'}>
          {title}
        </DText>
        <Margin height={8} />
        <DText fontSize={Fonts.font14} color={colorPalette.neutral['200']}>
          {'@' + selectedMember.name + ' hasn’t made any selections yet.'}
        </DText>
        <Margin height={16} />
        <BiggerTouchableOpacity
          onPress={closeProfile}
          style={{
            width: '100%',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            height: 48,
            backgroundColor: colorPalette.cobalt['400'],
          }}
        >
          <DText fontSize={Fonts.font16} fontWeight={600} color={'white'}>
            {'Back to Leaderboard'}
          </DText>
        </BiggerTouchableOpacity>
      </View>
    );
  };
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      directionalLockEnabled={true}
      contentContainerStyle={{
        justifyContent: 'flex-start',
        alignItems: 'center',

        flexGrow: 1,
      }}
      style={{ width: '100%', paddingRight: 28, paddingLeft: 28, flex: 1, zIndex: 2 }}
    >
      {renderUser()}
      {visibleChallenge && renderDateList()}
      <Margin height={16} />
      {!visibleChallenge ||
      (visibleChallenge.active && (!activeMemberPlayed || !selectedMemberPlayed))
        ? renderNotFinished()
        : visibleChallenge?.games?.map((game) => (
            <View key={game.id} style={{ width: width - 32 }}>
              <YourSelectionCard
                game={game}
                plays={selectedMemberPlays}
                userId={selectedMember.userId}
                name={selectedMember.name}
              />
            </View>
          ))}
      <Margin height={112} />
      {renderDateChoices()}
    </ScrollView>
  );
};
